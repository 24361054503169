import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonIcon,
  IonButton,
  IonList,
  IonItem,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonTitle,
  IonContent,
  IonRouterOutlet,
} from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import './../theme/global.css';

interface MonthListProps {
  months: any;
}

const MonthList: React.FC<MonthListProps> = ({ months }) => {
  return (
    <IonList>
      {months.map((item: any, index: number) => {
        return (
          <IonItem
            key={`reportItem${index}`}
            style={{
              display: 'flex',
              flex: 1,
            }}
            href={`/singlereport/${item.year}/${item.monthName}/${
              item.month
            }/${Math.round(item.consumption)}`}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <p className="cintac semibold">{item.fullName}</p>
              <IonIcon
                size="large"
                className="cintac black"
                icon={chevronForwardOutline}
                style={{ transform: 'scale(0.8)' }}
              />
            </div>
          </IonItem>
        );
      })}
    </IonList>
  );
};

const mapMonth: any = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};

interface TestProps {
  history?: any;
}
const Profile: React.FC<TestProps> = ({ history }) => {
  const [currentDate, setCurrentDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [year, setYear] = useState<any>(2020);
  const [months, setMonths] = useState<any>([]);
  const [date, setDate] = useState(currentDate);

  useEffect(() => {
    axios
      .get('/devices/4/measures/graph_data.json', {
        params: {
          window: 'year',
          date: date,
          variable_ids: [7],
        },
      })
      .then((res: any) => {
        const { fullLabels, year, pself } = res.data.graph_data;

        const months: any = [];
        fullLabels.forEach((item: any, index: number) => {
          const monthName = item.split(' ')[0];
          const month = mapMonth[monthName];
          const retrievedDate = dayjs(`${year}-${month}-01`);
          if (retrievedDate.isBefore(dayjs(currentDate), 'month')) {
            months.push({
              fullName: item,
              monthName,
              month,
              year,
              consumption: pself[index],
            });
          }
        });
        setMonths(months);
        setYear(year);
      })
      .catch((res: any) => {
        
      });
  }, [date]);

  const previousYear = () => {
    setDate(dayjs(date).subtract(1, 'year').format('YYYY-MM-DD'));
  };

  const nextYear = () => {
    setDate(dayjs(date).add(1, 'year').format('YYYY-MM-DD'));
  };

  return (
    <>
    <IonRouterOutlet id="menuItem"></IonRouterOutlet>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton slot="start" fill="clear" mode="ios">
            <IonMenuButton className="cintac darkGray" />
          </IonButton>
          <IonTitle>Reportes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: 24 }}>
          <h4 className="cintac bold">Estos son tus logros</h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <IonButton fill="clear" mode="ios" onClick={previousYear}>
              <IonIcon
                size="22"
                className="cintac darkGray"
                icon={chevronBackOutline}
              />
            </IonButton>
            <div
              style={{
                width: 200,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p className="cintac">{year}</p>
            </div>
            <IonButton fill="clear" mode="ios" onClick={nextYear}>
              <IonIcon
                size="22"
                className="cintac darkGray"
                icon={chevronForwardOutline}
              />
            </IonButton>
          </div>
          <MonthList months={months} />
        </div>
      </IonContent>
    </IonPage>
    </>
  );
};

export default Profile;
