import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonItem,
  IonList,
} from '@ionic/react';
import {
  homeOutline,
  documentOutline,
  statsChartOutline,
} from 'ionicons/icons';
import { logInRespSchema } from './schemas/schemas';
import dayjs from 'dayjs';

import Home from './pages/Home';
import EnergySource from './pages/EnergySources';
import Advices from './pages/Advices';
import Reports from './pages/Reports';
import SingleReport from './pages/SingleReport';
import ShareReport from './pages/ShareReport';
import HelpSlides from './pages/HelpSlides';
import Login from './pages/Login';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Axios config
// =======================
import axios from 'axios';
axios.defaults.baseURL = 'https://backend.pulse.zembia.cl';
// =======================

const version = '1.0.28';

dayjs.locale('es');

const useStateWithLocalStorage = (localStorageKey: any) => {
  const [value, setValue] = React.useState<any | any>(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

interface TabsProps {
  logout: any;
}

const Tabs: React.FC<TabsProps> = ({ logout }) => {
  const HomeWrapper: any = (props: any) => {
    return <Home {...props} logout={logout} />;
  };

  return (
    <>
      <IonMenu side="start" contentId="menuItem">
        <IonHeader>
          <IonToolbar style={{ padding: 16 }}>
            <p className="cintac bold">CINTAC</p>
            <p className="cintac small hint">ENERGÍA</p>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <IonList>
              <IonItem href="/advices">
                <p className="cintac bold">Consejos</p>
              </IonItem>
              <IonItem
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <p className="cintac bold">Cerrar sesión</p>
              </IonItem>
            </IonList>

            <IonList>
              <IonItem>
                <p className="cintac hint">Versión v{version}</p>
              </IonItem>
            </IonList>
          </div>
        </IonContent>
      </IonMenu>
      <IonTabs>
        <IonRouterOutlet>
          <Route
            exact 
            path="/tabs/home"
            component={HomeWrapper}
            componentProps={{ logout }}
          />
          <Route exact path="/tabs/consumption" component={EnergySource} />
          <Route exact path="/tabs/reports" component={Reports} />
          <Redirect exact from="/" to="/tabs/home" />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/tabs/home">
            <IonIcon icon={homeOutline} />
            <IonLabel>Inicio</IonLabel>
          </IonTabButton>
          <IonTabButton tab="consumption" href="/tabs/consumption">
            <IonIcon icon={statsChartOutline} />
            <IonLabel>Consumo</IonLabel>
          </IonTabButton>
          <IonTabButton tab="reports" href="/tabs/reports">
            <IonIcon icon={documentOutline} />
            <IonLabel>Reportes</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

const App: React.FC = () => {
  // const [loggedIn, setLoggedIn] = useStateWithLocalStorage('loggedIn');
  const [initLoading, setInitLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useStateWithLocalStorage('authToken');

  const login = (user: any, password: any, setShowAlert: any): any => {
    const params = {
      user: {
        email: user,
        password,
      },
    };

    axios
      .post('/login.json', params)
      .then(async (resp) => {
        if ((await logInRespSchema.isValid(resp)) === true) {
          const { authorization } = resp.headers;
          const authToken = authorization.replace('Bearer ', '');
          setAuthToken(authToken);
          axios.defaults.headers.common['Authorization'] = authorization;
          setLoggedIn(true);
          setInitLoading(false);
        } else {
          setInitLoading(false);
          setShowAlert(true);
        }
      })
      .catch((resp) => {
        setInitLoading(false);
        setShowAlert(true);
      });
  };

  const logout = () => {
    setTimeout(() => {
      setAuthToken(null);
      axios.defaults.headers.common['Authorization'] = null;
      setLoggedIn(false);
    }, 500);
  };

  useEffect(() => {
    if (authToken !== null && authToken !== 'null' && authToken !== '') {
      
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

      axios
        .get('./ping.json')
        .then((res) => {
          if (res.data.status === 'success') {
            setLoggedIn(true);
            setInitLoading(false);
          } else {
            setAuthToken(null);
            axios.defaults.headers.common['Authorization'] = null;
            setLoggedIn(false);
            setInitLoading(false);
          }
        })
        .catch((res) => {

          setAuthToken(null);
          axios.defaults.headers.common['Authorization'] = null;
          setLoggedIn(false);
          setInitLoading(false);
        });
    } else {
      setLoggedIn(false);
      setInitLoading(false);
    }
  }, []);

  const LoginWrapper: any = (props: any) => {
    return <Login {...props} login={login} />;
  };

  const TabsWrapper: any = (props: any) => {
    return <Tabs {...props} logout={logout} />;
  };

  if (initLoading === true) {
    return null;
  }

  return (
    <IonApp>
      <IonReactRouter>
        {loggedIn === true ? (
          <Switch>
            <Route path="/advices" component={Advices} />
            <Route
              path="/singlereport/:year/:monthName/:month/:consumption"
              component={SingleReport}
            />
            <Route
              path="/sharereport/:token"
              component={ShareReport}
            />
            <Route path="/helpslides" component={HelpSlides} />
            <Route
              path="/tabs"
              component={TabsWrapper}
              componentProps={{ logout: logout }}
            />
            <Redirect from="/" to="/tabs/home" />
          </Switch>
        ) : (
          <Switch>
            <Route
              path="/sharereport/:token"
              component={ShareReport}
            />
            <Route
              path="/login"
              component={LoginWrapper}
              componentProps={{ login: login }}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
