import React from 'react';
import './ComparationItem.css';
import './../theme/global.css';

interface ComparationItemProps {
  percent: any;
  name: any;
  value: any;
  colorClass: any;
}

const ComparationItem: React.FC<ComparationItemProps> = ({
  percent,
  name,
  value,
  colorClass,
}) => {
  let _value = '--';
  let _percent = 0;
  if (value !== null) {
    if (value === 0) {
      _value = parseFloat(value).toFixed(1);
      _percent = 0;
    } else {
      _value = parseFloat(value).toFixed(1);
      _percent = percent;
    }
  }

  return (
    <div className="cintac comparationItem">
      <p className="cintac" style={{ flex: 5 }}>
        {name}
      </p>
      <div className="cintac ratioBar" style={{ flex: 6 }}>
        <div
          className={'cintac ratioSmallBar ' + colorClass}
          style={{
            width: `${_percent}%`,
          }}
        />
      </div>
      <p className="cintac" style={{ flex: 2, textAlign: 'end' }}>
        {_value}kWh
      </p>
    </div>
  );
};

export default ComparationItem;
