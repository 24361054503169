import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonCard,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonRouterOutlet,
} from '@ionic/react';
import {
  home,
  homeOutline,
  helpCircleOutline,
  sunnyOutline,
  pulseOutline,
  sunny,
} from 'ionicons/icons';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { FaPlug } from 'react-icons/fa';
import { GiBatteryPack } from 'react-icons/gi';

import HeaderInfoBlock from './../components/HeaderInfoBlock';
import ComparationItem from './../components/ComparationItem';
import './Home.css';
import '../theme/global.css';
const humanizeDuration = require("humanize-duration");

dayjs.locale('es');
const MONEY_FACTOR = 110;

const currency = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});

interface TestProps {
  history?: any;
  logout: any;
}

const Home: React.SFC<TestProps> = (props) => {
  // const { logout } = props;
  const [lastGrid, setLastGrid] = useState<any>(null);
  const [lastGridFlow, setLastGridFlow] = useState<any>('');
  const [lastSolar, setLastSolar] = useState<any>(null);
  const [lastHome, setLastHome] =  useState<any>(null);
  const [lastUpdate, setLastUpdate] = useState<any>('--');
  const [batteryDuration, setBatteryDuration] = useState<any>(null);
  const [currentbattery, setCurrentBattery] = useState<any>(null);
  const [consumption, setConsumption] = useState<any>(null);
  const [generated, setGenerated] = useState<any>(null);
  const [injected, setInjected] = useState<any>(null);
  const [selfConsumption, setSelfConsumption] = useState<any>(null);
  const [importedFromGrid, setImportedFromGrid] = useState<any>(null);
  // const consumption = 100;
  let avgNeighborhoodCons: any = null;
  let efficientNeightborhoodCons: any = null;

  let maxConsumption = null;
  let minConsumption = null;
  let rangeConsumpion = null;
  let _consumption = null;
  let _avgNeighborhoodCons = null;
  let _efficientNeightborhoodCons = null;

  if (consumption !== null) {
    if (consumption === 0) {
      avgNeighborhoodCons = 100 + Math.random() * 20;
      efficientNeightborhoodCons = 0;
    } else {
      avgNeighborhoodCons = consumption * (1 + 0.2 * Math.random());
      efficientNeightborhoodCons = consumption * (0.7 + 0.3 * Math.random());
    }
    const consumptionList: any = [
      consumption,
      avgNeighborhoodCons,
      efficientNeightborhoodCons,
    ];
    maxConsumption = Math.max(...consumptionList);
    minConsumption = Math.min(...consumptionList);
    maxConsumption = maxConsumption + (maxConsumption - minConsumption) * 0.1;
    minConsumption = minConsumption - (maxConsumption - minConsumption) * 0.1;
    rangeConsumpion = maxConsumption - minConsumption;
    _consumption = (100 * (consumption - minConsumption)) / rangeConsumpion;
    _avgNeighborhoodCons =
      (100 * (avgNeighborhoodCons - minConsumption)) / rangeConsumpion;
    _efficientNeightborhoodCons =
      (100 * (efficientNeightborhoodCons - minConsumption)) / rangeConsumpion;
  }

  useEffect(()=> {
    axios
      .get('/devices/4/measures/latest.json',{params:{
        measure_names: ['ppv','bat','pself','pacToUser', 'pacToGrid']
      }})
      .then((res) => {   
        const solar = res.data.data.ppv.value; 
        const battery = res.data.data.bat.value;            
        const home = res.data.data.pself.value + res.data.data.pacToUser.value;
        const duration = battery*5*60*60*1000/100;
        const pacToUser = Math.abs(res.data.data.pacToUser.value);
        const pacToGrid = Math.abs(res.data.data.pacToGrid.value);
        setCurrentBattery(`${Math.round(battery)}%`);        
        setBatteryDuration(humanizeDuration(duration,{ language: "es", units: ['h','m'], round:true }));
        setLastUpdate(dayjs(res.data.data.ppv.origin_created_at).format('YYYY-MM-DD HH:mm'));
        setLastSolar(parseFloat(solar).toFixed(1));
        setLastHome(parseFloat(home).toFixed(1));

        if (pacToGrid > pacToUser) {
          setLastGrid((pacToGrid-pacToUser).toFixed(1));
          setLastGridFlow('(Inyectando)');
        } else if (pacToUser > pacToGrid){
          setLastGrid((pacToUser-pacToGrid).toFixed(1));
          setLastGridFlow('(Consumiendo)');
        } else {
          setLastGrid(0);
          setLastGridFlow('');
        }
        
      })
      .catch((res)=>{
        setCurrentBattery(null);
        setBatteryDuration('--');
      });
  },[])

  useEffect(() => {
    axios
      .get('/devices/4/measures/month_stats.json', {
        params: {
          date: dayjs().format('YYYY-MM-DD'),
          measure_name_ids: [7, 9, 5, 8],
        },
      })
      .then((res) => {
        const _selfConsumption = res.data.data[7];
        const _importedFromGrid = res.data.data[9];
        const homeConsumption =
          parseFloat(_selfConsumption) + parseFloat(_importedFromGrid);
        const _generated = res.data.data[5];
        const _injected = res.data.data[8];

        setConsumption(homeConsumption);
        setGenerated(_generated);
        setInjected(_injected);
        setSelfConsumption(_selfConsumption);
        setImportedFromGrid(_importedFromGrid);
      })
      .catch((res) => {
      });
  }, []);

  return (
    <>
      <IonRouterOutlet id="menuItem"></IonRouterOutlet>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButton slot="start" fill="clear" mode="ios">
              <IonMenuButton className="cintac darkGray" />
            </IonButton>
            <IonTitle>Inicio</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div>
            {/* MAIN CARD */}
            <div className="">
              <IonCard
                className="card main headerCard headerBackground"
                mode="ios"
              >
                {/* HEADER */}
                <div className="header">
                  <h5 className="cintac white semibold">Resumen solar</h5>
                  <IonButton fill="clear" size="small" href="/helpslides">
                    <IonIcon size="large" icon={helpCircleOutline} />
                  </IonButton>
                </div>

                {/* BODY */}
                <div className="body">
                  <h1 className="cintac white bold">
                    {selfConsumption === null
                      ? '--'
                      : currency.format(selfConsumption * MONEY_FACTOR)}
                  </h1>
                  <p className="cintac white regular">
                    Ahorrado hasta el día{dayjs().format(' dddd D ')}de
                    {dayjs().format(' MMMM, YYYY')}
                  </p>
                </div>
              </IonCard>
              {/* FOOTER */}
              <div className="blocksContainer">
                <HeaderInfoBlock
                  value={consumption}
                  name="Consumido"
                  icon={homeOutline}
                />
                <HeaderInfoBlock
                  value={generated}
                  name="Generado"
                  icon={sunnyOutline}
                />
                <HeaderInfoBlock
                  value={injected}
                  name="Inyectado"
                  icon={pulseOutline}
                />
              </div>
            </div>

            {/* ADD BILLS */}
            {/* <IonCard
              className="card horizontal border noShadow infoBackground"
              mode="ios"
            >
              
              <div style={{ alignSelf: 'center', padding: 25 }}>
                <IonIcon
                  size="large"
                  icon={informationCircle}
                  style={{ color: '#838185' }}
                />
              </div>

              
              <div className="cardPaddingVertical contentInfoBlock">
                <div className="header">
                  <h6 className="cintac semibold">
                    Sácale provecho a Cintac solar
                  </h6>
                </div>

                <p className="cintac">
                  Ingresa tus boletas anteriores para ver el impacto de la
                  energía solar
                </p>

                <div style={{ height: 30 }} />
                <IonButton
                  fill="clear"
                  size="small"
                  className="clearButton cintac"
                >
                  <p className="cintac small bold electricBlue">
                    Ingresar mis boletas
                  </p>
                  <IonIcon
                    className="electricBlue"
                    slot="end"
                    icon={chevronForward}
                    style={{ transform: 'scale(0.9)' }}
                  />
                </IonButton>
              </div>

              
              <div className="cardPaddingTop">
                <IonButton fill="clear" size="small">
                  <IonIcon
                    size="large"
                    icon={closeOutline}
                    style={{
                      transform: 'scale(0.9)',
                      color: 'black',
                    }}
                  />
                </IonButton>
              </div>
            </IonCard> */}

            {/* ENERGY CURRRENT STATUS */}
            <IonCard className="card border cardPadding noShadow cintacInvert" mode="ios">
              {/* HEADER */}
              <div className="header">
                <div className="vertical">
                  <h6 className="cintac semibold">Estado actual</h6>
                  <p className="cintac hint">Última actualización: {lastUpdate}</p>
                </div>
              </div>              

              {/* SUMMARY */}
              <div>
                <div className="summaryItemSpace" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <IonIcon
                        className="cintac"
                        size="large"
                        icon={sunny}
                        style={{
                          transform: 'scale(0.8)',
                        }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Paneles solares
                    </p>
                  </div>

                  <p className="cintac">
                    {lastSolar ??  '--'} kW
                  </p>
                </div>
                <div className="summaryItemSpace" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <FaPlug
                        size="16"
                        color="white"
                        style={{ verticalAlign: 'middle' }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Red eléctrica {lastGridFlow}
                    </p>
                  </div>
                  <div className="flexCenter">
                    <p className="cintac">
                      {lastGrid ?? '--'} kW
                    </p>
                  </div>
                </div>
                <div className="summaryItemSpace" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <IonIcon
                        className="cintac"
                        size="large"
                        icon={home}
                        style={{ transform: 'scale(0.7)' }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Consumo del hogar
                    </p>
                  </div>

                  <p className="cintac">
                    {lastHome ?? '--'} kW
                  </p>
                </div>
              </div>
            </IonCard>


            {/* BATTERY */}
            <IonCard className="card border cardPadding noShadow" mode="ios">
              <div className="summaryItem">
                <div className="flexCenter">
                  <div className="summaryIconWrapper cintac darkBlueBackground">
                    <GiBatteryPack
                      color="white"
                      size="22"
                      style={{
                        verticalAlign: 'middle',
                        transform: 'rotate(45deg)',
                      }}
                    />
                  </div>
                  <div style={{ paddingLeft: 15 }}>
                    <h6 className="cintac semibold">Carga de batería</h6>
                    <p className="cintac hint">
                      Duración estimada: {batteryDuration}
                    </p>
                  </div>
                </div>

                <p className="cintac">{currentbattery ?? '--'}</p>
              </div>
            </IonCard>


            {/* ENERGY SOURCE */}
            <IonCard className="card border cardPadding noShadow" mode="ios">
              {/* HEADER */}
              <div className="header">
                <div className="vertical">
                  <h6 className="cintac semibold">De dónde viene mi energía</h6>
                  <p className="cintac hint">Último mes · 27 feb al 6 mar</p>
                </div>
              </div>

              {/* RATIO BAR */}
              <div
                className="cintac orangeBackground"
                style={{
                  borderRadius: 8,
                  overflow: 'hidden',
                  height: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  className="cintac darkBlueBackground"
                  style={{
                    height: '100%',
                    width: '60%',
                  }}
                />
              </div>

              {/* SUMMARY */}
              <div>
                <div className="summaryItemSpace" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <IonIcon
                        className="cintac darkBlue"
                        size="large"
                        icon={sunny}
                        style={{
                          transform: 'scale(0.8)',
                        }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Paneles solares
                    </p>
                  </div>

                  <p className="cintac">
                    {selfConsumption === null
                      ? '--'
                      : parseFloat(selfConsumption).toFixed(1)}{' '}
                    kWh
                  </p>
                </div>
                <div className="summaryItemSpace" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <FaPlug
                        size="16"
                        color="#e5530d"
                        style={{ verticalAlign: 'middle' }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Red eléctrica
                    </p>
                  </div>
                  <div className="flexCenter">
                    <p className="cintac">
                      {importedFromGrid === null
                        ? '--'
                        : parseFloat(importedFromGrid).toFixed(1)}{' '}
                      kWh
                    </p>
                  </div>
                </div>
                <div className="summaryItemSpace cintac separator" />
                <div className="summaryItem">
                  <div className="flexCenter">
                    <div className="summaryIconWrapper">
                      <IonIcon
                        className="cintac darkGray"
                        size="large"
                        icon={home}
                        style={{ transform: 'scale(0.7)' }}
                      />
                    </div>
                    <p className="cintac" style={{ paddingLeft: 15 }}>
                      Consumo del hogar
                    </p>
                  </div>

                  <p className="cintac">
                    {consumption === null
                      ? '--'
                      : parseFloat(consumption).toFixed(1)}{' '}
                    kWh
                  </p>
                </div>
              </div>
            </IonCard>           

            {/* COMPARATION */}
            <IonCard className="card border cardPadding noShadow" mode="ios">
              {/* HEADER */}
              <div className="header">
                <div className="vertical">
                  <h6 className="cintac semibold">
                    Comparación con hogares similares
                  </h6>
                  <p className="cintac hint">Último mes · 27 feb al 6 mar</p>
                </div>
              </div>

              {/* RATIO BAR */}
              <ComparationItem
                value={consumption}
                percent={_consumption}
                name="Tu consumo"
                colorClass="darkBlueBackground"
              />
              <ComparationItem
                value={avgNeighborhoodCons}
                percent={_avgNeighborhoodCons}
                name="Vecino promedio"
                colorClass="darkGrayBackground"
              />
              <ComparationItem
                value={efficientNeightborhoodCons}
                percent={_efficientNeightborhoodCons}
                name="Vecino eficiente"
                colorClass="yellowBackground"
              />
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
