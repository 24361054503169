import React, {useEffect, useState} from 'react';
  import {
    IonPage,
    IonTitle,
    IonImg,
  } from '@ionic/react';
  import dayjs from 'dayjs';
  
  import './../theme/global.css';
  import earthImage from './../img/earth.png';
  import plantImage from './../img/plant.png';
  import bulbImage from './../img/bulb.png';
  import Helmet from 'react-helmet';
  
  const jwt = require('jsonwebtoken');
  
  const separatedNumber = (number: number) => {
    return new Intl.NumberFormat('en-US',{style:'decimal'}).format(number).replace(',','.');
  }
  
  const ShareReport = (props: any) => {
    const [loading, setLoading] = useState<Boolean>(true);  
    const [bulbs, setBulbs] = useState<any>(null);
    const [trees, setTrees] = useState<any>(null);
    const [co2Emissions, setCo2Emissions] = useState<any>(null);
    const [year, setYear] = useState<any>(null);
    const [monthName, setMonthName] = useState<any>(null);
    const [consumption, setConsumption] = useState<any>(null);
    const [month, setMonth] = useState<any>(null);
    
    const { token } = props.match.params;  
  
    const sicEmissions = 0.3765;
    const treesAbsortion = 1.6;  
    const hoursPerDay = 1;
  
    useEffect(() => {
      const privateKey = '7HPvrhF48wNu8T3PMT6d9CVPTVSb6i8B';      
      const {year, monthName, consumption, month} = jwt.verify(token, privateKey);
      const monthDays = dayjs(`${year}-${month}-01`).endOf('month').date();
      setBulbs(Math.round(consumption / (hoursPerDay * monthDays)));
      setTrees(Math.round((consumption * sicEmissions) / treesAbsortion));
      setCo2Emissions(Math.round(consumption / sicEmissions));
      setYear(year);
      setMonthName(monthName);
      setConsumption(consumption);
      setMonth(month);
      setLoading(false);
    },[]);
  
    if (loading) {
      return (
        <IonPage
        style={{
          flex: 1,
          height: '100%',
          justifyContent: 'flex-start',
          backgroundColor: '#2dc3d8',
        }}
      ></IonPage>  
      )
    }
  
    return (
      <>    
        <IonPage
          style={{
            flex: 1,
            height: '100%',
            paddingTop: 20,
            justifyContent: 'flex-start',
            backgroundColor: '#2dc3d8',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
  
            <IonTitle className="cintac white">
              {monthName} {year}
            </IonTitle>
          </div>
          <div
            style={{
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              paddingRight: 36,
              marginTop: 0,
            }}
          >
            <h1
              style={{
                fontSize: 100,
                fontWeight: 900,
                color: '#25a2c5',
                marginTop: 0,
              }}
            >
              {separatedNumber(consumption)}
            </h1>
            <h1
              style={{
                fontSize: 28,
                fontWeight: 800,
                color: '#25a2c5',
                paddingBottom: 20,
              }}
            >
              kWh
            </h1>
          </div>
  
          <div style={{ paddingLeft: 36 }}>
            <p
              style={{
                color: 'white',
                fontSize: 28,
                fontWeight: 500,
                padding: 0,
                margin: 0,
                lineHeight: 1.2,
              }}
            >
              Generaste{' '}
              <span style={{ fontSize: 46, fontWeight: 800 }}>{separatedNumber(consumption)}</span>
              <span style={{ fontSize: 28, fontWeight: 500 }}>kWh</span>
            </p>
            <p
              style={{
                color: 'white',
                fontSize: 28,
                fontWeight: 500,
                padding: 0,
                margin: 0,
                lineHeight: 1.5,
              }}
            >
              con tus paneles solares
            </p>
  
            <p
              style={{
                color: 'white',
                fontSize: 28,
                fontWeight: 500,
                padding: 0,
                margin: 0,
                lineHeight: 1.5,
              }}
            >
              {`en ${monthName.toLowerCase()} ${year}`}
            </p>
          </div>
          <div style={{ height: 60 }}></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: 'white',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonImg alt="oli" src={earthImage} style={{ width: 80 }} />
            </div>
            <p
              style={{
                color: 'white',
                fontSize: 16,
                lineHeight: 1.4,
                fontWeight: 400,
                flex: 2,
              }}
            >
              Ahorraste{' '}
              <span style={{ fontWeight: 600, fontSize: 24 }}>
                {separatedNumber(co2Emissions)}kg
              </span>
              <br />
              de emisiones de CO2 al planeta
            </p>
          </div>
  
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: 'white',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonImg alt="oli" src={plantImage} style={{ width: 80 }} />
            </div>
            <p
              style={{
                color: 'white',
                fontSize: 16,
                lineHeight: 1.4,
                fontWeight: 400,
                flex: 2,
              }}
            >
              Es el equivalente a{' '}
              <span style={{ fontWeight: 600, fontSize: 24 }}>{separatedNumber(trees)}</span>
              <br />
              nuevos árboles plantados
            </p>
          </div>
  
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: 'white',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonImg alt="oli" src={bulbImage} style={{ width: 80 }} />
            </div>
            <p
              style={{
                color: 'white',
                fontSize: 16,
                lineHeight: 1.4,
                fontWeight: 400,
                flex: 2,
              }}
            >
              Que es igual a evitar{' '}
              <span style={{ fontWeight: 600, fontSize: 24 }}>{separatedNumber(bulbs)}</span>
              <br />
              ampolletas encendidas
              <br />
              por una hora
            </p>
          </div>
        </IonPage>
      </>
    );
  };
  
  export default ShareReport;
  
