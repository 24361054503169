import React from 'react';
import { IonIcon } from '@ionic/react';
import './HeaderInfoBlock.css';
import './../theme/global.css';

interface HeaderInfoBlockProps {
  value: any;
  icon: any;
  name: any;
}

const HeaderInfoBlock: React.FC<HeaderInfoBlockProps> = ({
  icon,
  value,
  name,
}) => {
  let _value = value === null ? '--' : parseFloat(value).toFixed(0);

  return (
    <div className="container">
      <div className="InfoBlockTop">
        <IonIcon
          size="large"
          icon={icon}
          style={{ color: 'white', transform: 'scale(0.61)' }}
        />
      </div>
      <div className="InfoBlockMiddle">
        <div className="value bold">{_value}</div>
        <div className="valueUnit regular">kWh</div>
      </div>
      <div className="InfoBlockBottom">
        <span className="type semibold">{name}</span>
      </div>
    </div>
  );
};

export default HeaderInfoBlock;
