import React from 'react';
import {
  IonSlides,
  IonSlide,
  IonContent,
  IonButton,
  IonBackButton,
  IonButtons,
  IonImg,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import slideImage_1 from './../img/Group 7-A1.svg';
import slideImage_2 from './../img/Group 7-A2.svg';
import slideImage_3 from './../img/Group 7-A3.svg';
import slideImage_4 from './../img/Group 7-A4.svg';

// Optional parameters to pass to the swiper instance.
// See http://idangero.us/swiper/api/ for valid options.
const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

const slidesContent = [
  {
    text: (
      <>
        El <span style={{ fontWeight: 700 }}>Resumen solar</span> muestra los
        indicadores más importantes de tu sistema de energía solar.
      </>
    ),
    img: slideImage_1,
    backButton: false,
  },
  {
    text: (
      <>
        <span style={{ fontWeight: 700 }}>Consumo</span> muestra la energía
        consumida por tu casa. Esa energía puede venir desde el sistema solar
        (que son los paneles y la batería) o la red eléctrica.
      </>
    ),
    img: slideImage_2,
    backButton: false,
  },
  {
    text: (
      <>
        <span style={{ fontWeight: 700 }}>Generado</span> muestra la energía
        generada por tu sistema de energía solar. Esta energía puede ir directo
        al consumo de tu casa, inyectarse a la red eléctrica o cargar las
        baterías.
      </>
    ),
    img: slideImage_3,
    backButton: false,
  },
  {
    text: (
      <>
        <span style={{ fontWeight: 700 }}>Inyectado</span> muestra la energía
        que sale de tu sistema de energía solar y se inyecta a la red eléctrica.
        Puede salir de los paneles solares o las baterías. Este aporte de
        energía te lo paga la compañia de electricidad con quien tienes el
        contrato. Consulta con ellos para más información.
      </>
    ),
    img: slideImage_4,
    backButton: false,
  },
  {
    text: (
      <>
        <span style={{ fontWeight: 700 }}>Ahorrado</span> es una estimación del
        dinero que has ahorrado por no comprar energía a tu compañia de
        electricidad y que la consumes de tus paneles. Este es un cálculo de
        referencia y depende de factores como el valor del kWh al que te venden
        energía.
        <br />
        <br /> Para calcular asumimos que tu compañía de distribución te vende
        energía a $110/kWh (IVA incl.)
        <br />
        <br />
        Tu ahorro se calcula como:
        <br />
        Ahorrado = $110 x Energía Generada
      </>
    ),
    img: null,
    backButton: true,
  },
];
interface SlideProps {
  text: any;
  img: any;
  history: any;
  backButton: any;
}

const Slide: React.FC<SlideProps> = ({ text, img, history, backButton }) => {
  return (
    <IonSlide
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* <div style={{ flex: 1, width: '100%' }}>
        <IonButtons slot="start">
          <IonBackButton
            text=""
            icon={closeOutline}
            className="cintac black"
            defaultHref="/tabs/home"
            style={{ marginLeft: -16 }}
          />
        </IonButtons>
      </div> */}
      <div style={{ flex: 3 }}>
        <p className="cintac" style={{ textAlign: 'left' }}>
          {text}
        </p>
      </div>
      <div
        style={{
          flex: 6,
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {img !== null && <IonImg src={img} style={{ width: '100%' }} />}
      </div>
      <div style={{ flex: 2, width: '100%' }}>
        {backButton && (
          <IonBackButton
            text="ENTENDIDO"
            defaultHref="/tabs/home"
            icon=""
            className="cintac darkBlueBackground white"
            style={{ borderRadius: 3 }}
          />
        )}
      </div>
    </IonSlide>
  );
};

interface HelpSlidesProps {
  history?: any;
}

const HelpSlides: React.FC<HelpSlidesProps> = ({ history }) => (
  <IonContent>
    <div
      style={{
        flex: 1,
        width: '100%',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: -8,
      }}
    >
      <IonButtons slot="start">
        <IonBackButton
          text=""
          icon={closeOutline}
          className="cintac black"
          defaultHref="/tabs/home"
          style={{ marginLeft: 0 }}
        />
      </IonButtons>
    </div>
    <IonSlides
      pager={true}
      options={slideOpts}
      style={{ height: '75%', margin: 24 }}
    >
      {slidesContent.map((slide, index) => (
        <Slide
          key={`slide_${index}`}
          text={slide.text}
          img={slide.img}
          history={history}
          backButton={slide.backButton}
        />
      ))}
    </IonSlides>
  </IonContent>
);

export default HelpSlides;
