import React, { useState } from 'react';
import {
  IonAlert,
  IonSpinner,
  IonContent,
  IonImg,
  IonItem,
  IonInput,
  IonPage,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { eyeOutline, eyeOffOutline, personOutline } from 'ionicons/icons';

import logoLogin from './../img/logo_login.png';
import './../theme/global.css';

interface LoginProps {
  login: any;
}

const Login: React.FC<LoginProps> = ({ login }) => {
  const [user, setUser] = useState<string | any>('');
  const [password, setPassword] = useState<string | any>('');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const passwordInputType: any = passwordShown ? 'type' : 'password';
  const disableLogin = loading || user === '' || password === '';
  return (
    <IonContent>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Error'}
        subHeader={'Credenciales invalidas'}
        message={
          'Las credenciales ingresadas no son válidas. Por favor revise su usuario o contraseña.'
        }
        buttons={['Aceptar']}
      />
      <IonPage
        style={{
          padding: 24,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <IonImg
          alt="logo cintac"
          src={logoLogin}
          style={{
            width: '60%',
            maxWidth: 240,
            paddingTop: 24,
            paddingBottom: 48,
          }}
        />
        <div style={{ width: '100%', justifyContent: 'flex-start' }}>
          <h4 className="cintac bold">Inicio de sesión</h4>
        </div>
        <form style={{ width: '100%' }}>
          <IonItem style={{ width: '100%', marginTop: 24 }}>
            <IonInput
              value={user}
              placeholder="Nombre de usuario"
              onIonChange={(e) => setUser(e.detail.value)}
              style={{ justifyContent: 'flex-end' }}
            ></IonInput>
            <IonIcon size="large" icon={personOutline} />
          </IonItem>
          <IonItem style={{ width: '100%', marginTop: 24 }}>
            <IonInput
              type={passwordInputType}
              value={password}
              placeholder="Contraseña"
              onIonChange={(e) => setPassword(e.detail.value)}
              style={{ justifyContent: 'flex-end' }}
              onKeyDown={(e) => {
                const key = typeof e.which === 'number' ? e.which : e.keyCode;
                if (key === 13) {
                  if (!disableLogin) {
                    setLoading(true);
                    login(user, password, (status: any) => {
                      setLoading(false);
                      setShowAlert(status);
                    });
                  }
                }
              }}
            ></IonInput>
            <IonIcon
              size="large"
              icon={passwordShown ? eyeOutline : eyeOffOutline}
              onClick={() => setPasswordShown(!passwordShown)}
            />
          </IonItem>
          <div style={{ width: '100%', marginTop: 24 }}>
            <IonButton
              color="#0a4e97"
              size="large"
              className="cintac darkBlueBackground roundButton"
              disabled={disableLogin}
              onClick={() => {
                setLoading(true);
                login(user, password, (status: any) => {
                  setLoading(false);
                  setShowAlert(status);
                });
              }}
              style={{ width: '100%' }}
            >
              <h6 className="cintac semibold white">
                {loading ? <IonSpinner /> : 'INGRESAR'}
              </h6>
            </IonButton>
          </div>
        </form>
      </IonPage>
    </IonContent>
  );
};

export default Login;
