import React, {useEffect, useState} from 'react';
import {
  IonPage,
  IonTitle,
  IonImg,
  IonIcon,
  IonButton,
  IonBackButton,
} from '@ionic/react';
import { arrowBackOutline, chevronForward, logoTwitter, logoFacebook } from 'ionicons/icons';
import dayjs from 'dayjs';

import './../theme/global.css';
import earthImage from './../img/earth.png';
import plantImage from './../img/plant.png';
import bulbImage from './../img/bulb.png';

const jwt = require('jsonwebtoken');

const separatedNumber = (number: number) => {
  return new Intl.NumberFormat('en-US',{style:'decimal'}).format(number).replace(',','.');
}

const SingleReport = (props: any) => {
  const { year, monthName, consumption, month } = props.match.params;
  const [shareToken, setShareToken] = useState<any>(null);

  const sicEmissions = 0.3765;
  const treesAbsortion = 1.6;
  const monthDays = dayjs(`${year}-${month}-01`).endOf('month').date();
  const hoursPerDay = 1;

  const bulbs = Math.round(consumption / (hoursPerDay * monthDays));
  const trees = Math.round((consumption * sicEmissions) / treesAbsortion);
  const co2Emissions = Math.round(consumption / sicEmissions);

  useEffect(() => {
    const jwtCreation = () => {
      const privateKey = '7HPvrhF48wNu8T3PMT6d9CVPTVSb6i8B';
      const token = jwt.sign({ year, monthName, consumption, month }, privateKey);
      setShareToken(token);    
    }
    jwtCreation();
  },[]);


  return (
    <IonPage
      style={{
        flex: 1,
        height: '100%',
        justifyContent: 'flex-start',
        backgroundColor: '#2dc3d8',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <IonBackButton
          defaultHref="/tabs/reports"
          text=""
          style={{ color: 'white' }}
          icon={arrowBackOutline}
        />

        <IonTitle className="cintac white">
          {monthName} {year}
        </IonTitle>
      </div>
      <div
        style={{
          alignSelf: 'flex-end',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          paddingRight: 36,
          marginTop: 0,
        }}
      >
        <h1
          style={{
            fontSize: 100,
            fontWeight: 900,
            color: '#25a2c5',
            marginTop: 0,
          }}
        >
          {separatedNumber(consumption)}
        </h1>
        <h1
          style={{
            fontSize: 28,
            fontWeight: 800,
            color: '#25a2c5',
            paddingBottom: 20,
          }}
        >
          kWh
        </h1>
      </div>

      <div style={{ paddingLeft: 36 }}>
        <p
          style={{
            color: 'white',
            fontSize: 28,
            fontWeight: 500,
            padding: 0,
            margin: 0,
            lineHeight: 1.2,
          }}
        >
          Generaste{' '}
          <span style={{ fontSize: 46, fontWeight: 800 }}>{separatedNumber(consumption)}</span>
          <span style={{ fontSize: 28, fontWeight: 500 }}>kWh</span>
        </p>
        <p
          style={{
            color: 'white',
            fontSize: 28,
            fontWeight: 500,
            padding: 0,
            margin: 0,
            lineHeight: 1.5,
          }}
        >
          con tus paneles solares
        </p>

        <p
          style={{
            color: 'white',
            fontSize: 28,
            fontWeight: 500,
            padding: 0,
            margin: 0,
            lineHeight: 1.5,
          }}
        >
          {`en ${monthName.toLowerCase()} ${year}`}
        </p>
      </div>
      <div
        style={{
          alignSelf: 'flex-start',
          paddingLeft: 36,
          paddingTop: 16,
          paddingBottom: 8,
        }}
      >
        <IonButton fill="clear" size="small" className="clearButton cintac" href={`https://www.facebook.com/sharer/sharer.php?u=https://cintac.zembia.cl/share.html?token=${shareToken}`}>
          <p className="cintac  bold darkBlue">COMPARTIR EN FACEBOOK</p>
          <IonIcon className="cintac darkBlue" style={{paddingLeft: 8, paddingRight: 4}}icon={logoFacebook} />
          <IonIcon className="cintac darkBlue" icon={chevronForward} />
        </IonButton>
      </div>
      <div
        style={{
          alignSelf: 'flex-start',
          paddingLeft: 36,          
          paddingBottom: 8,
        }}
      >
        <IonButton fill="clear" size="small" className="clearButton cintac" href={`https://twitter.com/intent/tweet?url=https://cintac.zembia.cl/sharereport/${shareToken}&text=`}>
          <p className="cintac  bold darkBlue">COMPARTIR EN TWITTER</p>
          <IonIcon className="cintac darkBlue" style={{paddingLeft: 8, paddingRight: 4}}icon={logoTwitter} />
          <IonIcon className="cintac darkBlue" icon={chevronForward} />
        </IonButton>
      </div>
      <div style={{ height: 24 }}></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          color: 'white',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonImg alt="oli" src={earthImage} style={{ width: 80 }} />
        </div>
        <p
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: 1.4,
            fontWeight: 400,
            flex: 2,
          }}
        >
          Ahorraste{' '}
          <span style={{ fontWeight: 600, fontSize: 24 }}>
            {separatedNumber(co2Emissions)}kg
          </span>
          <br />
          de emisiones de CO2 al planeta
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          color: 'white',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonImg alt="oli" src={plantImage} style={{ width: 80 }} />
        </div>
        <p
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: 1.4,
            fontWeight: 400,
            flex: 2,
          }}
        >
          Es el equivalente a{' '}
          <span style={{ fontWeight: 600, fontSize: 24 }}>{separatedNumber(trees)}</span>
          <br />
          nuevos árboles plantados
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          color: 'white',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonImg alt="oli" src={bulbImage} style={{ width: 80 }} />
        </div>
        <p
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: 1.4,
            fontWeight: 400,
            flex: 2,
          }}
        >
          Que es igual a evitar{' '}
          <span style={{ fontWeight: 600, fontSize: 24 }}>{separatedNumber(bulbs)}</span>
          <br />
          ampolletas encendidas
          <br />
          por una hora
        </p>
      </div>
    </IonPage>
  );
};

export default SingleReport;
