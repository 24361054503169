import * as yup from 'yup';

export const logInRespSchema = yup.object().shape({
  headers: yup
    .object()
    .shape({
      authorization: yup.string().required(),
    })
    .required(),
});
