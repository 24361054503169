import React from 'react';
import {
  IonPage,
  IonIcon,
  IonButton,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { starOutline, closeOutline } from 'ionicons/icons';
import './../theme/global.css';

const advices = [
  'Prefiere equipos de eficiencia energética clase A o A+.',
  'Evita dejar dispositivos e iluminación encendidos si no los estás utilizando.',
  'Para generar la mayor energía de tus paneles, preocúpate de limpiarlos al menos 3 veces al año.',
  'No te preocupes, nosotros te informaremos si vemos que tus paneles no están en su punto óptimo.',
  'Para sacarle el mayor provecho a tu sistema solar, busca que el consumo solar sea mayor que el consumo de la red eléctrica.',
  'Para lograrlo, busca mover la mayor cantidad de consumo al horario entre 11:00 y 16:00',
];

interface AdviceProps {
  text: string;
}

const Advice: React.FC<AdviceProps> = ({ text }) => {
  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <IonIcon
          size="large"
          icon={starOutline}
          className="cintac greenStar"
          style={{ transform: 'scale(0.8)' }}
        />
      </div>
      <div style={{ flex: 8, marginBottom: 12 }}>
        <p className="cintac">{text}</p>
      </div>
    </div>
  );
};

interface TestProps {
  history?: any;
}
const Profile: React.FC<TestProps> = ({ history }) => (
  <IonPage
    style={{
      flex: 1,
      height: '100%',
      justifyContent: 'flex-start',
    }}
  >
    <div
      style={{
        width: '100%',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: -16,
      }}
    >
      <IonButtons slot="start">
        <IonBackButton
          text=""
          icon={closeOutline}
          className="cintac black"
          defaultHref="/tabs/home"
          style={{ marginLeft: 0 }}
        />
      </IonButtons>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginBottom: 32,
        padding: 24,
        height: '90%',
      }}
    >
      <div>
        <h5 className="cintac bold">
          Sácale el mayor provecho a la eficiencia energética y a tu sistema
          fotovoltaico siguiento estos consejos
        </h5>
        <h6
          className="cintac semibold"
          style={{ marginTop: 32, marginBottom: 16 }}
        >
          Recomendaciones
        </h6>
        <>
          {advices.map((item, index) => {
            return <Advice key={`advice_${index}`} text={item} />;
          })}
        </>
      </div>

      <IonButton
        color="#0a4e97"
        size="large"
        className="cintac darkBlueBackground roundButton"
        href="/tabs/home"
      >
        <h6 className="cintac semibold white">ENTENDIDO</h6>
      </IonButton>
    </div>
  </IonPage>
);

export default Profile;
