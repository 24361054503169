import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';

import {
  IonIcon,
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonChip,
  IonButton,
  IonMenuButton,
  IonRouterOutlet,
  IonHeader,
} from '@ionic/react';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import {
  alertCircleOutline,
  chevronForwardOutline,
  chevronBackOutline,
} from 'ionicons/icons';

import './EnergySources.css';
import './../theme/global.css';

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          drawOnChartArea: false,
        },
      },
    ],
    yAxes: [{ stacked: true }],
  },
};

interface ItemListProps {
  dataNet: any;
  dataSolar: any;
  fullLabels: any;
  loadingState: any;
  year: any;
}

const ItemList: React.FC<ItemListProps> = ({
  dataNet,
  dataSolar,
  fullLabels,
  loadingState,
  year,
}) => {
  const validData =
    loadingState === 'success' && dataNet.length > 0 && fullLabels.length > 0;
  if (validData) {
    return (
      <IonList>
        {fullLabels.map((item: any, index: any) => {
          const key = `consumptionList${index}`;
          if (dataNet[index] === null) {
            return <NullItem key={key} />;
          } else {
            return (
              <ValidItem
                key={key}
                name={`${item}`}
                net={dataNet[index]}
                solar={dataSolar[index]}
              />
            );
          }
        })}
      </IonList>
    );
  } else {
    return null;
  }
};

interface ChartProps {
  options: any;
  loadingState: string;
  dataNet: any;
  dataSolar: any;
  labels: any;
}

const ChartView: React.FC<ChartProps> = ({
  options,
  loadingState,
  dataNet,
  dataSolar,
  labels,
}) => {
  let statusMsg = null;
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Red eléctrica',
        backgroundColor: '#e5530d',
        hoverBackgroundColor: '#e5530da0',
        data: dataNet,
        barThickness: 12,
      },
      {
        label: 'Energía solar',
        backgroundColor: '#0a4e97',
        hoverBackgroundColor: '#0a4e97',
        data: dataSolar,
        barThickness: 12,
      },
    ],
  };

  if (loadingState === 'loading') {
    statusMsg = (
      <div className="statusMsg">
        <h6 className="bold">Cargando información</h6>
      </div>
    );
  } else if (loadingState === 'failure') {
    statusMsg = (
      <div className="statusMsg">
        <h6 className="bold">Error al cargar la información</h6>
      </div>
    );
  } else if (loadingState === 'none') {
    statusMsg = (
      <div className="statusMsg">
        <h6 className="bold">No hay información para desplegar</h6>
      </div>
    );
  } else if (loadingState === 'success' && chartData.labels.length === 0) {
    statusMsg = (
      <div className="statusMsg">
        <h6 className="bold">No hay información para desplegar</h6>
      </div>
    );
  }

  return (
    <div
      style={{
        position: 'relative',
        height: 220,
        padding: '15px 15px',
      }}
    >
      <div
        style={{
          filter: loadingState === 'success' ? '' : 'blur(3px)',
          width: '100%',
          height: '100%',
        }}
      >
        <Bar data={chartData} width={100} height={50} options={options} />
      </div>
      {statusMsg}
    </div>
  );
};

const NullItem: React.FC = () => (
  <IonItem>
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        padding: '14px 0px',
      }}
    >
      <IonIcon
        size="large"
        icon={alertCircleOutline}
        style={{ opacity: 0.3 }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          flexGrow: 1,
          padding: '0px 10px',
        }}
      >
        <span style={{ fontWeight: 500 }}>Sin datos</span>
        <span style={{ fontWeight: 400, fontSize: 12, opacity: 0.4 }}>
          Enero 2019
        </span>
      </div>
    </div>
  </IonItem>
);

interface ValidItemProps {
  name: string;
  solar: any;
  net: any;
}

const ValidItem: React.FC<ValidItemProps> = ({ name, solar, net }) => (
  <IonItem>
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        padding: '14px 0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          flexGrow: 1,
          padding: '0px 10px',
        }}
      >
        <h6 className="cintac semibold">
          {parseFloat(solar + net).toFixed(1)}kWh
        </h6>
        <p className="cintac hint">{name}</p>
      </div>

      <div>
        <IonChip className="cintac lightBlueBackground">
          <IonLabel className="cintac darkBlue">
            {parseFloat(net).toFixed(1)}kWh
          </IonLabel>
        </IonChip>
        <IonChip className="cintac lightOrangeBackground">
          <IonLabel className="cintac orange">
            {parseFloat(solar).toFixed(1)}kWh
          </IonLabel>
        </IonChip>
      </div>
    </div>
  </IonItem>
);

const reduceDecimals = (dataList: any) => {
  const newDataList = dataList.map((item: any) => {
    return parseFloat(item.toFixed(2));
  });
  return newDataList;
};

const EnergySource: React.FC = () => {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [period, setPeriod] = useState<any>('');
  const [year, setYear] = useState<any>(null);
  const [dataNet, setDataNet] = useState<any>([]);
  const [dataSolar, setDataSolar] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [fullLabels, setFullLabels] = useState<any>([]);
  const [loadingState, setLoadingState] = useState('none'); // Success/Failure/None/Loading
  const [selectedSegment, setSelectedSegment] = useState('year');

  const loadData = (field: string) => {
    setLoadingState('loading');

    axios
      .get('/devices/4/measures/graph_data.json', {
        params: {
          window: field,
          date: date,
          variable_ids: [7, 9],
        },
      })
      .then((res: any) => {
        const {
          fullLabels,
          labels,
          period,
          pacToUser,
          pself,
          year,
        } = res.data.graph_data;

        setDataNet(reduceDecimals(pacToUser));
        setDataSolar(reduceDecimals(pself));
        setLabels(labels);
        setFullLabels(fullLabels);
        setYear(year);
        setPeriod(period);
        setLoadingState('success');
      })
      .catch((res: any) => {
      });
  };

  useEffect(() => {
    setLoadingState('loading');
    loadData('year');
  }, []);

  useEffect(() => {
    loadData(selectedSegment);
  }, [date]);

  const previousPeriod = () => {
    switch (selectedSegment) {
      case 'year':
        setDate(dayjs(date).subtract(1, 'year').format('YYYY-MM-DD'));
        break;
      case 'month':
        setDate(dayjs(date).subtract(1, 'month').format('YYYY-MM-DD'));
        break;
      case 'week':
        setDate(dayjs(date).subtract(1, 'week').format('YYYY-MM-DD'));
        break;
      case 'day':
        setDate(dayjs(date).subtract(1, 'day').format('YYYY-MM-DD'));
        break;
    }
  };

  const nextPeriod = () => {
    switch (selectedSegment) {
      case 'year':
        setDate(dayjs(date).add(1, 'year').format('YYYY-MM-DD'));
        break;
      case 'month':
        setDate(dayjs(date).add(1, 'month').format('YYYY-MM-DD'));
        break;
      case 'week':
        setDate(dayjs(date).add(1, 'week').format('YYYY-MM-DD'));
        break;
      case 'day':
        setDate(dayjs(date).add(1, 'day').format('YYYY-MM-DD'));
        break;
    }
  };

  return (
   
    <>
    <IonRouterOutlet id="menuItem"></IonRouterOutlet>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButton slot="start" fill="clear" mode="ios">
            <IonMenuButton className="cintac darkGray" />
          </IonButton>
          <IonTitle>Consumo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonSegment
        onIonChange={(e: CustomEvent) => setSelectedSegment(e.detail.value)}
        value={selectedSegment}
      >
        <IonSegmentButton value="year" onClick={() => loadData('year')}>
          <IonLabel>Año</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="month" onClick={() => loadData('month')}>
          <IonLabel>Mes</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="week" onClick={() => loadData('week')}>
          <IonLabel>Semana</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="day" onClick={() => loadData('day')}>
          <IonLabel>Día</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <IonButton fill="clear" mode="ios" onClick={previousPeriod}>
          <IonIcon
            size="22"
            className="cintac darkGray"
            icon={chevronBackOutline}
          />
        </IonButton>
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p className="cintac">{year}</p>
          {period !== '' && <p className="cintac hint">{period}</p>}
        </div>
        <IonButton fill="clear" mode="ios" onClick={nextPeriod}>
          <IonIcon
            size="22"
            className="cintac darkGray"
            icon={chevronForwardOutline}
          />
        </IonButton>
      </div>
      <ChartView
        dataNet={dataNet}
        dataSolar={dataSolar}
        labels={labels}
        options={options}
        loadingState={loadingState}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 6,
            paddingRight: 6,
            alignItems: 'center',
          }}
        >
          <div
            className="cintac orangeBackground"
            style={{
              height: 16,
              width: 16,
              borderRadius: 8,
              marginLeft: 6,
              marginRight: 6,
            }}
          ></div>
          <p className="cintac hint">Red eléctrica</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 6,
            paddingRight: 6,
            alignItems: 'center',
          }}
        >
          <div
            className="cintac darkBlueBackground"
            style={{
              height: 16,
              width: 16,
              borderRadius: 8,
              marginLeft: 6,
              marginRight: 6,
            }}
          ></div>
          <p className="cintac hint">Energía solar</p>
        </div>
      </div>

      <IonContent
        scrollY={true}
        style={{ flexGrow: 1, paddingLeft: 16, paddingRight: 16 }}
      >
        <IonList>
          <ItemList
            dataNet={dataNet}
            dataSolar={dataSolar}
            fullLabels={fullLabels}
            loadingState={loadingState}
            year={year}
          />
        </IonList>
      </IonContent>
    </IonPage>
    </>
  );
};

export default EnergySource;
